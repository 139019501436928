/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
} from "react";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";
import {
  formatLocalTime,
  getUrlImage,
  selectEmpty,
  showToast,
} from "utils/common";
import VDialog from "components/v-dialog";
import VConfirm from "components/v-confirm";
import Service from "services/categories";
import ProductsService from "services/products";
import Detail from "./components/Detail";
import debounce from "lodash.debounce";
import { Dropdown } from "primereact/dropdown";
import { TreeTable } from "primereact/treetable";
import { Image } from "primereact/image";
import { useParams } from "react-router-dom";
import CategoryFilterService from "services/category-filters";
import { copyText } from "utils/common";
import StatusEditorCell from "components/v-edit-cell/StatusEditorCell";
import NumberEditorCell from "components/v-edit-cell/NumberEditorCell";
import { Panel } from "primereact/panel";
import { CATEGORY_EXTRA_FORM_GROUP, COMMON_STATUS } from "utils/enum";
import { MasterContext } from "contexts/MasterContext";
import { CATEGORY_GROUP_KEY } from "utils/constant";
import { useHistory } from "react-router-dom";
import EditProfileBanners from "../users/components/edit-profile-banners";
import AuthService from "services/auth";
import { IObject } from "types";
import { TreeSelect } from "primereact/treeselect";

const Crud = () => {
  const { attributeSets } = useContext(MasterContext);
  const { group }: IObject = useParams();
  const history = useHistory();

  const [categories, setCategories] = useState([]);
  const [treeData, setTreeData] = useState([]);
  const [detail, setDetail] = useState(null);
  const [onlyView, setOnlyView] = useState(false);
  const [parentId, setParentId] = useState(null);
  const [globalFilter, setGlobalFilter] = useState({
    search: "",
    start: null,
    end: null,
    parent_id: null,
    status: "enable",
    attribute_id: null,
  });
  const [lazyParams, setLazyParams]: any = useState({
    sortField: "createdAt",
    sortOrder: -1,
  });
  const [userId, setUserId] = useState("");
  const [categoryFilters, setCategoryFilters] = useState([]);

  const toast = useRef(null);
  const dt = useRef(null);
  const refDialogDetail = useRef(null);
  const refDialogDelete = useRef(null);
  const refDialogEditBanner = useRef(null);
  const refEditBanner = useRef(null);
  const refDetail = useRef(null);
  const [keySearch, setKeySearch] = useState("");

  useEffect(() => {
    if (group) loadData();
  }, [group, lazyParams, globalFilter]);

  useEffect(() => {
    getCategoryFilters();
  }, []);

  const getCategoryFilters = async () => {
    try {
      const res: any = await CategoryFilterService.getAll();
      if (res) {
        setCategoryFilters(res);
      }
    } catch (error) {}
  };

  const loadData = async () => {
    try {
      const res: any = await Service.getAll({
        query: {
          isTree: true,
          group: group,
          ...globalFilter,
          ...lazyParams,
          search: keySearch,
        },
      });
      if (categories.length === 0) {
        setCategories(res);
      }
      setTreeData(res);
    } catch (error) {}
  };

  //TABLE EVENT
  const onPage = (event) => {
    let _lazyParams = { ...lazyParams, ...event };
    setLazyParams(_lazyParams);
  };
  const onSort = (event) => {
    let _lazyParams = { ...lazyParams, ...event };
    setLazyParams(_lazyParams);
  };
  const onFilter = (name, value) => {
    setGlobalFilter((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const onSearch = useCallback(debounce(onFilter, 500), []);

  //CREATE OR NEW
  const openNew = () => {
    setOnlyView(false);
    setDetail({});
    setParentId(null);
    refDialogDetail.current.show();
  };

  const editMenuCategory = (data) => {
    setOnlyView(false);
    setDetail(categories?.find((e) => e?._id === data?._id));
    refDialogDetail.current.show();
  };

  const addChild = (data) => {
    let childData = { parent_id: data._id, group: group };
    setDetail({ ...childData });
    refDialogDetail.current.show();
  };

  const viewCategory = (data) => {
    history.push(`/users-by-type/${data?.keyVal}`);
  };

  const onCancelDialogDetail = () => {
    setOnlyView(false);
    refDialogDetail.current.close();
  };

  const onSumitDialogDetail = () => {
    refDetail.current.submit();
  };

  const setLoadingSaveDetail = (flg) => {
    refDialogDetail.current.setLoading(flg);
  };

  // Banners
  const editProfileBanners = async (data: any) => {
    setUserId(data.user_id);
    refDialogEditBanner.current.show();
  };
  const onSubmitDialogBanner = () => {
    refEditBanner.current.submit();
  };
  const onCancelDialogBanner = () => {
    refDialogEditBanner.current.close();
  };
  const setLoadingEditBanner = (flg) => {
    refDialogEditBanner.current.setLoading(flg);
  };

  // DELETE
  const confirmDelete = (product) => {
    setDetail(product);
    refDialogDelete.current.show();
  };
  const handleDelete = async () => {
    try {
      await Service.deleted({
        params: {
          id: detail._id,
        },
      });
      loadData();
      refDialogDelete.current.close();
      setDetail({});
      showToast(toast, "success", "Category Deleted");
    } catch (error) {
      showToast(toast, "error", error.errors);
    }
  };

  const onChangeStatus = async (options, rowData, value) => {
    if (rowData) {
      await Service.update({
        params: { id: rowData._id },
        body: {
          [options.field]: value,
        },
      });
      loadData();
    }
  };
  const onChangeOrder = debounce(onChangeStatus, 700);

  const clearCache = async () => {
    try {
      await ProductsService.clearCache();
      showToast(toast, "success", "Cache Deleted");
    } catch (error) {
      showToast(toast, "error", error.errors);
    }
  };

  const handleLoginSocial = async (userId: string) => {
    try {
      const userToken = await AuthService.getUserToken({
        userId: userId,
      });
      if (userToken) {
        return window.open(
          `${process.env.REACT_APP_CLIENT_DOMAIN}?token=${userToken}`
        );
      }
    } catch (error) {
      console.log("error:", error);
    }
  };

  const onSearchByKey = (e: any) => {
    if (e.key === "Enter") {
      handleFilterTree();
    }
  };

  const handleFilterTree = () => {
    // if (keySearch?.length > 0) {
    loadData();
    // }
  };

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <Button
            label="New"
            icon="pi pi-plus"
            className="p-button-success mr-2"
            onClick={openNew}
          />

          <Button
            label="Delete"
            icon="pi pi-trash"
            className="p-button-danger"
            // onClick={confirmDeleteSelected}
            // disabled={!selectedProducts || !selectedProducts.length}
            disabled={true}
          />
        </div>
      </React.Fragment>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        {CATEGORY_EXTRA_FORM_GROUP.PRODUCT_CATEGORY_NORMAL === group && (
          <Button
            label="Clear Cache"
            icon="pi pi-trash"
            className="p-button-help mr-2"
            onClick={clearCache}
          />
        )}

        <FileUpload
          mode="basic"
          accept="image/*"
          maxFileSize={1000000}
          name="Import"
          chooseLabel="Import"
          disabled
          className="mr-2 inline-block"
        />
        <Button
          label="Export"
          icon="pi pi-upload"
          disabled
          className="p-button-help"
          // onClick={exportCSV}
        />
      </React.Fragment>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        {/* {!rowData.leaf && ( */}
        <Button
          icon="pi pi-plus"
          className="p-button-rounded p-button-sm p-button-success mr-2"
          onClick={() => addChild(rowData)}
        />
        {/* // )} */}
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-sm p-button-success mr-2"
          onClick={() => editMenuCategory(rowData)}
        />

        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-sm p-button-warning mr-2"
          onClick={() => confirmDelete(rowData)}
        />

        <Button
          icon="pi pi-user"
          className="p-button-rounded p-button-sm p-button-warning mr-2"
          onClick={() => editProfileBanners(rowData)}
        />

        <Button
          icon="pi pi-sign-in"
          className="p-button-rounded p-button-sm mr-2"
          onClick={() => handleLoginSocial(rowData.user_id)}
        />

        {CATEGORY_EXTRA_FORM_GROUP.ACCOUNT_TYPE === group && (
          <Button
            icon="pi pi-eye"
            className="p-button-rounded p-button-sm p-button-success"
            onClick={() => viewCategory(rowData)}
          />
        )}
      </div>
    );
  };

  const transformData = (data) => {
    return data.map((item) => ({
      key: item._id,
      keyVal: item.key,
      label: item.name,
      children: item.children ? transformData(item.children) : [],
    }));
  };

  const nodes = transformData(categories);

  const header = (
    <Panel header="Filter" toggleable collapsed={false}>
      <div className="grid my-2 align-items-center">
        <div className="col-12 md:col-6">
          <span className="block m-1 p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              type="search"
              // value={globalFilter.search}
              // onChange={(e) => onSearch("search", e.target.value)}
              value={keySearch}
              onInput={(e: any) => setKeySearch(e.target.value)}
              onKeyDown={onSearchByKey}
              placeholder="Search..."
              className="w-full"
            />
          </span>
        </div>
        <div className="col-12 md:col-6">
          <TreeSelect
            placeholder="Select Parent Menu"
            inputId="treeselect"
            value={globalFilter.parent_id}
            onChange={(e) => onSearch("parent_id", e.value)}
            options={[selectEmpty, ...nodes]}
            className="w-full"
            filter
          />
        </div>
        <div className="col-12 md:col-6">
          <Dropdown
            value={globalFilter.status}
            options={Object.keys(COMMON_STATUS).map((k) => ({
              label: k,
              value: COMMON_STATUS[k],
            }))}
            onChange={(e: any) => onSearch("status", e.value)}
            optionLabel="label"
            placeholder="Status"
            className={"w-full"}
            showClear
          />
        </div>
        <div className="col-12 md:col-6">
          <Dropdown
            value={globalFilter.attribute_id}
            options={attributeSets?.map((k) => ({
              label: k.title,
              value: k._id,
            }))}
            optionLabel="label"
            optionValue="value"
            placeholder="Attribute"
            filter
            onChange={(e) => onSearch("attribute_id", e.value)}
            className="w-full"
            showClear
          />
        </div>
      </div>
    </Panel>
  );

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />
          <Toolbar
            className="mb-4"
            left={leftToolbarTemplate}
            right={rightToolbarTemplate}
          ></Toolbar>
          <TreeTable
            ref={dt}
            value={treeData}
            header={header}
            // paginator
            rowsPerPageOptions={[5, 10, 25]}
            emptyMessage="No data found."
            lazy
            first={lazyParams.first}
            rows={lazyParams.rows}
            sortField={lazyParams.sortField}
            sortOrder={lazyParams.sortOrder}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="{first} - {last} of {totalRecords}"
            onPage={onPage}
            onSort={onSort}
          >
            <Column
              // frozen
              // alignFrozen="left"
              body={actionBodyTemplate}
              style={{ flexGrow: 1, flexBasis: "260px" }}
            ></Column>
            {/* <Column
              field="key"
              header="Key"
              style={{ flexGrow: 1, flexBasis: "150px" }}

              body={(rowData) => <span>{rowData.key}</span>}
            ></Column> */}
            <Column
              field="name"
              header="Name"
              expander
              style={{ flexGrow: 1, flexBasis: "180px" }}
              body={(rowData) => <span>{rowData.name}</span>}
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "30px" }}
              header="Icon"
              body={(rowData) => {
                return (
                  <Image
                    src={getUrlImage(rowData.icon)}
                    alt={rowData.name}
                    width="50"
                    height="50"
                    preview
                  />
                );
              }}
            ></Column>
            <Column
              // editor={orderEditor}
              field="order"
              header="Order"
              style={{ flexGrow: 1, width: "8%" }}
              body={(rowData, options) => (
                <NumberEditorCell
                  rowData={rowData}
                  value={rowData.order}
                  options={options}
                  onChange={(options, value) =>
                    onChangeOrder(options, rowData, value)
                  }
                />
              )}
              sortable
            ></Column>
            <Column
              // editor={statusEditor}
              field="status"
              header="Status"
              style={{ flexGrow: 1, flexBasis: "100px" }}
              // body={(rowData) => (
              //   <span
              //     className={`v-badge status-${rowData.status?.toLowerCase()}`}
              //   >
              //     {rowData.status}
              //   </span>
              // )}
              body={(rowData, options) => (
                <StatusEditorCell
                  value={rowData.status}
                  options={options}
                  rowData={rowData}
                  onChange={onChangeStatus}
                />
              )}
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "100px" }}
              field="createdAt"
              header="Created At"
              sortable
              body={(rowData) => formatLocalTime(rowData.createdAt)}
            ></Column>

            <Column
              style={{ flexGrow: 1, flexBasis: "100px" }}
              field="updatedAt"
              header="Updated At"
              sortable
              body={(rowData) => formatLocalTime(rowData.updatedAt)}
            ></Column>

            {group === CATEGORY_GROUP_KEY.BANNER_PAGE && (
              <Column
                field="banner"
                header="Link banner"
                style={{ flexGrow: 1, width: "8%" }}
                body={(rowData) => (
                  <Button
                    onClick={(_) => {
                      copyText(
                        `${process.env.REACT_APP_CLIENT_DOMAIN}/banners?menuKey=&positionBanner=${rowData.key}&userId=&notLoadDefault=true`
                      );
                      showToast(toast, "success", "Copied to clipboard!");
                    }}
                    icon="bx bx-copy"
                  ></Button>
                )}
              ></Column>
            )}
          </TreeTable>

          <VDialog
            ref={refDialogDetail}
            header="Detail"
            onSubmit={onSumitDialogDetail}
            onlyView={onlyView}
          >
            {detail && (
              <Detail
                ref={refDetail}
                data={detail}
                group={group}
                toast={toast}
                categoryFilters={categoryFilters}
                categories={nodes}
                parentId={parentId}
                reload={loadData}
                setLoading={setLoadingSaveDetail}
                onCancel={onCancelDialogDetail}
                getCategoryFilters={getCategoryFilters}
                onlyView={onlyView}
              />
            )}
          </VDialog>

          <VConfirm ref={refDialogDelete} onConfirm={handleDelete} />

          <VDialog
            ref={refDialogEditBanner}
            header="Edit profile banners"
            onSubmit={onSubmitDialogBanner}
          >
            <EditProfileBanners
              userId={userId}
              ref={refEditBanner}
              toast={toast}
              reload={loadData}
              setLoading={setLoadingEditBanner}
              onCancel={onCancelDialogBanner}
            />
          </VDialog>
        </div>
      </div>
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Crud, comparisonFn);
